import React from 'react';
import { CheckCircleFilled, MinusCircleFilled, ShopOutlined } from '@ant-design/icons';
import { Flex } from "antd";


const Checklist = ({ data, items, fanBadges }) => {
    const checkedItems = items.map(item => {
        const fanBadge = fanBadges.find(badge => badge.id === item.badgeId);
        const isCompleted = fanBadge ? fanBadge.badgeCompletion : false;
        return { ...item, isCompleted };
    });
    const completedCount = checkedItems.filter(item => item.isCompleted).length;
    const totalCount = checkedItems.length;
    return (
        <div className="checklist">
            <div className="checklist-header">
                {data.iconURL && <img src={data.iconURL} alt="Checklist Icon" className="checklist-icon" />}

                <h1>{data.name}</h1>
            </div>
            <Flex className='completion-message' align='center'>
                <ShopOutlined style={{ fontSize: "30px", color: "#000" }} />
                <span style={{ marginLeft: "5px" }}><strong>Congrats,</strong> you've completed <strong>{completedCount}/{totalCount}</strong> goals!</span>
            </Flex>
            {data.items && (
                <ul className="checklist-items">
                    {checkedItems.map(item => (
                        <li key={item.id} className="checklist-item">
                            <span className="check-item-mark">
                                {item.isCompleted ?
                                    <CheckCircleFilled style={{ color: '#1958a8', fontSize: "20px" }} /> :
                                    <MinusCircleFilled style={{ color: "#605d5d", fontSize: "20px" }} />
                                }
                            </span>

                            <span className="check-item-text">{item.description}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Checklist;
