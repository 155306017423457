import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Flex, Row, Image, Form, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getParamsApi, getApi } from "../services/api.jsx";

const BadgePreview = () => {
  const [badgeData, setBadgeData] = useState("");
  const [loading, setLoading] = useState(false);
  const [yinzcamId, setYinzcamId] = useState(
    localStorage.getItem("yinzcamId") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEarned = queryParams.get("isEarned") === "true";
  const { id } = useParams();
  const navigate = useNavigate();
  const goHomeButton = () => {
    navigate(`/?yinzcamId=${yinzcamId}&email=${email}`);
  };

  const getBadgeData = (badgeId) => {
    const params = { id: badgeId };
    getParamsApi(`/badge/${badgeId}`, params)
      .then((response) => {
        setBadgeData(response);
      })
      .catch((error) => {
        console.error("Error fetching badge data:", error);
      });
  };

  useEffect(() => {
    getBadgeData(id);
  }, [id]);

  return (
    <>
      <div
        style={{
          backgroundImage: badgeData.backgroundImageURL
            ? `url(${badgeData.backgroundImageURL})`
            : "none",
          backgroundSize: "cover",
          backgroundColor: badgeData.backgroundColor
            ? badgeData.backgroundColor
            : "#000",
          textAlign: "center",
          width: "100vw",
          flexDirection: "column",
        }}
        className="w-100 h-100"
        vertical
        justify="space-between"
      >
        <>
          {" "}
          <a
            onClick={goHomeButton}
            rel="noopener noreferrer"
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              fontSize: "13px",
              textDecoration: "none",
            }}
          >
            <ArrowLeftOutlined
              style={{
                display: "flex",
                padding: "10px",
                fontSize: "18px",
                color: "white",
              }}
            />
            <span>Home</span>
          </a>
          <div className="text-center p-15">
            <Image
              src={badgeData.imageURL}
              alt="Badge"
              style={{
                width: "250px",
                height: "250px",
                opacity: isEarned ? 1 : 0.4,
              }}
            />
            <h3
              style={{
                color: badgeData.fontColor ? badgeData.fontColor : "#fff",
                padding: "0 15px",
              }}
            >
              {badgeData.headline}
            </h3>
            <div
              style={{
                color: badgeData.fontColor ? badgeData.fontColor : "#fff",
                padding: "0 20px",
              }}
              dangerouslySetInnerHTML={{
                __html: badgeData.description,
              }}
            />
            {badgeData.buttonName && (
              <Button
                type="primary"
                className="no-radius"
                style={{
                  backgroundColor: badgeData.buttonColor,
                  color: badgeData.buttonTextColor,
                  borderColor: badgeData.buttonTextColor,
                }}
              >
                <a
                  href={"https://" + badgeData.buttonLinkURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: badgeData.buttonTextColor,
                    textDecoration: "none",
                  }}
                >
                  {badgeData.buttonName}
                </a>
              </Button>
            )}
          </div>
          <div>
            <iframe
              className="prev-iframe"
              width="100%"
              height="315"
              src={badgeData.videoURL}
              title={badgeData.name}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style={{ marginTop: "30px" }}
            ></iframe>
          </div>
        </>
      </div>

      {loading && (
        <div className="spinner-overlay">
          <div className="spinner" />
        </div>
      )}
    </>
  );
};

export default BadgePreview;
