import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

import "swiper/css/bundle";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

const Carousel = ({ slides }) => {
  const navigate = useNavigate();

  const badgePreview = (id, isEarned) => {
    if (isEarned) {
      navigate(`/badge-preview/${id}?isEarned=${isEarned}`);
    }
  };

  return (
    <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      slidesPerView={3}
      grid={{
        rows: 1,
      }}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide
          key={slide.id}
          onClick={() => badgePreview(slide.id, slide.isEarned)}
        >
          <Tooltip
            title={
              slide.hoverDescription && !slide.isEarned
                ? slide.hoverDescription.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "")
                : ""
            }
          >
            <div className="slide-content" style={{ marginTop: "10px" }}>
              <img
                src={slide.imageURL}
                alt={slide.altText || `Slide ${index + 1}`}
                style={{
                  width: "100px",
                  height: "100px",
                  opacity: slide.isEarned ? "1" : "0.3",
                }}
              />
            </div>
          </Tooltip>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
