import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Flex, Image, Empty, Row, Col, Button } from "antd";
import { getApi, getParamsApi, loginApi } from "../services/api";
import axios from "axios";
import Checklist from "../components/checklist";
import Carousel from "../components/carousel";
import { DoubleRightOutlined } from "@ant-design/icons";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const Main = () => {
  const query = useQuery();
  const [yinzcamId, setYinzcamId] = useState(
    localStorage.getItem("yinzcamId") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const { Content } = Layout;
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);
  const [fanBadges, setFanBadges] = useState([]);
  const [userChecklist, setUserChecklist] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [checklistId, setChecklistId] = useState("");
  const [checklistItems, setChecklistItems] = useState([]);
  const [token, setToken] = useState(() => localStorage.getItem("token") || "");
  const [categoryDetails, setCategoryDetails] = useState({});

  const [categoryBadges, setCategoryBadges] = useState({});
  const [infoMessageList, setInfoMessageList] = useState({});

  useEffect(() => {
    const yinzcamIdFromUrl = query.get("yinzcamId");
    const emailFromUrl = query.get("email");

    if (yinzcamIdFromUrl && emailFromUrl) {
      setYinzcamId(yinzcamIdFromUrl);
      setEmail(emailFromUrl);
      localStorage.setItem("yinzcamId", yinzcamIdFromUrl);
      localStorage.setItem("email", emailFromUrl);
    }
  }, [query]);

  const getToken = async () => {
    const params = new URLSearchParams();
    params.append("grant_type", "client_credentials");
    params.append("client_id", process.env.REACT_APP_CLIENT_ID);
    params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_TOKEN_URL,
        params,
        config
      );
      const newToken =
        response.data.token_type + " " + response.data.access_token;
      localStorage.setItem("token", newToken);
      setToken(newToken);
      const now = new Date();
      const expirationTime = new Date(
        now.getTime() + response.data.expires_in * 1000
      );
      localStorage.setItem("tokenExpirationTime", expirationTime);
    } catch (error) {
      console.error("Error fetching the token:", error);
    }
  };

  useEffect(() => {
    const expiryTime = localStorage.getItem("tokenExpirationTime");
    const parsedExpiryTime = expiryTime ? new Date(expiryTime) : null;
    const storedToken = localStorage.getItem("token");

    if (storedToken && parsedExpiryTime.getTime() > new Date().getTime()) {
      setToken(storedToken);
      getTemplate();
      getInfoMessage();
    } else {
      getToken();
    }
  }, [token]);

  useEffect(() => {
    if (categoryId) {
      getCategoryBadges(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (checklistId) {
      getUserChecklist(checklistId);
    }
  }, [checklistId]);

  useEffect(() => {
    if (yinzcamId && email) {
      getFanBadges(yinzcamId, email);
    }
  }, [yinzcamId, email]);

  const getTemplate = () => {
    setLoading(true);
    getApi("/template/list")
      .then((template) => {
        setContents(template);
        template.forEach((item) => {
          if (item.type === "CATEGORY") {
            setCategoryId(item.id);
            getCategoryBadges(item.id);
            getUserCategory(item.id);
          } else if (item.type === "CHECKLIST") {
            setChecklistId(item.id);
          }
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetching template:", error);
        setLoading(false);
      });
  };

  const getUserCategory = (id) => {
    setLoading(true);
    const params = { id: id };
    getParamsApi(`/category/${id}`, params)
      .then((response) => {
        setCategoryDetails((prevDetails) => ({
          ...prevDetails,
          [id]: {
            name: response.name,
            placementType: response.placementType,
            infoMessage: response.infoMessage,
            iconURL: response.iconURL,
            backgroundImageURL: response.backgroundImageURL,
            fontColor: response.fontColor,
            font: response.font,
            lineColor: response.lineColor,
            shape: response.shape,
            layout: response.layout,
            paddingTop: response.paddingTop,
            paddingBottom: response.paddingBottom,
            paddingRight: response.paddingRight,
            paddingLeft: response.paddingLeft,
            infoMessage: response.infoMessage,
            infoBackgroundColor: response.infoBackgroundColor,
            infoFontColor: response.infoFontColor,
            tagBackgroundColor: response.tagBackgroundColor,
            tagFontColor: response.tagFontColor,
            tagMessage: response.tagMessage,
            buttonColor: response.buttonColor,
            buttonLinkURL: response.buttonLinkURL,
            buttonName: response.buttonName,
            buttonTextColor: response.buttonTextColor,
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
        setLoading(false);
      });
  };

  const getCategoryBadges = (categoryId) => {
    setLoading(true);
    const params = { categoryId: categoryId };
    getParamsApi(`/badge/category/${categoryId}`, params)
      .then((response) => {
        const activeBadges = response.filter((badge) => badge.isActive);
        setCategoryBadges((prevBadges) => ({
          ...prevBadges,
          [categoryId]: activeBadges,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching badgess:", error);
        setLoading(false);
      });
  };

  const getFanBadges = (yinzcamId, email) => {
    setLoading(true);
    const params = { yinzcamId, email };
    getParamsApi(`/badge/fan`, params)
      .then((response) => {
        const activeBadges = response.badgeFanDTOList.filter(
          (badge) => badge.isActive
        );
        setFanBadges(activeBadges);
      })
      .catch((error) => {
        console.error("Error fetching fan badges:", error);
        setLoading(false);
      });
  };

  const getUserChecklist = (checklistId) => {
    setLoading(true);
    const params = { id: checklistId };
    getParamsApi(`/checklist/${checklistId}`, params)
      .then((response) => {
        setUserChecklist(response);
        setChecklistItems(response.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching checklist:", error);
        setLoading(false);
      });
  };

  const getInfoMessage = () => {
    setLoading(true);
    getApi("/infoMessage/list")
      .then((infoMessage) => {
        setInfoMessageList(infoMessage);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetching template:", error);
        setLoading(false);
      });
  };

  const template =
    contents &&
    contents.length > 0 &&
    contents
      .sort((a, b) => a.order - b.order)
      .map((content) => {
        const categoryInfo = categoryDetails[content.id];
        const badgesForCategory = categoryBadges[content.id] || [];
        const fanBadgesForCategory = fanBadges.filter(
          (badge) => badge.categoryId === content.id
        );
        const allBadges = badgesForCategory.concat(fanBadgesForCategory);
        const info = Array.isArray(infoMessageList)
          ? infoMessageList.find(
              (infoMessage) => infoMessage.id === content.id
            ) || null
          : null;
        const combinedBadges = allBadges.reduce((acc, badge) => {
          const existingBadge = acc.find((item) => item.id === badge.id);
          const fanVersion = fanBadgesForCategory.find(
            (fanBadge) => fanBadge.id === badge.id
          );

          if (existingBadge) {
            existingBadge.isEarned = fanVersion
              ? fanVersion.badgeCompletion
              : existingBadge.isEarned || false;
          } else {
            acc.push({
              ...badge,
              isEarned: fanVersion ? fanVersion.badgeCompletion : false,
            });
          }
          return acc;
        }, []);

        switch (content.type) {
          case "TEXT":
            return (
              <div className="full-width-centered" key={content.id}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: content.title || "Default Text",
                  }}
                  style={content.styles}
                ></p>
              </div>
            );
          case "INFO":
            return (
              info && (
                <div
                  className="full-width-centered-large"
                  key={info.id}
                  style={{
                    background: info.backgroundColor,
                    backgroundImage: `url(${info.backgroundImageURL})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    padding: "20px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    margin: "10px",
                  }}
                >
                  {info.iconURL && (
                    <img
                      src={info.iconURL}
                      alt="Icon"
                      style={{
                        width: "35px",
                        height: "35px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <span style={{ color: "#fff", fontFamily: "inherit" }}>
                    {info.description}
                  </span>
                </div>
              )
            );
          case "LINK":
            return (
              <div className="full-width-centered" key={content.id}>
                <a
                  className="custom-link"
                  href={content.description}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={content.styles}
                >
                  {content.title || "Default Text"}
                </a>
              </div>
            );
          case "IMAGE":
            return (
              <div className="app-space" key={content.id}>
                <img
                  src={content.url}
                  alt={content.description || "Image"}
                  style={{ width: "100%" }}
                />
              </div>
            );
          case "VIDEO":
            return (
              <div key={content.id} className="video-responsive app-space">
                <iframe
                  width="100%"
                  height="315"
                  src={content.url}
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={content.title || "Video"}
                ></iframe>
              </div>
            );
          case "CHECKLIST":
            return (
              <div className="app-space" key={content.id}>
                <Checklist
                  data={userChecklist}
                  items={checklistItems}
                  fanBadges={fanBadges}
                />
              </div>
            );
          case "CATEGORY":
            return (
              <div
                key={content.id}
                className="app-space category"
                style={{
                  borderRadius: categoryInfo?.shape == "Rounded" ? "10px" : "0",
                  paddingLeft: categoryInfo?.paddingLeft,
                  paddingBottom: categoryInfo?.paddingBottom,
                  paddingTop: categoryInfo?.paddingTop,
                  paddingRight: categoryInfo?.paddingRight,
                  backgroundImage: `url(${
                    categoryInfo?.backgroundImageURL || ""
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <Flex
                  align="center"
                  style={{
                    margin: "0 15px",
                    borderBottom: `1px solid ${
                      categoryInfo && categoryInfo.lineColor
                        ? categoryInfo.lineColor
                        : "white"
                    }`,
                  }}
                >
                  {categoryInfo && categoryInfo.iconURL && (
                    <Image
                      src={categoryInfo.iconURL}
                      alt="Category Icon"
                      className="category-icon"
                      style={{ width: "25px" }}
                    />
                  )}
                  <h1
                    className="landing-headline"
                    style={{ color: categoryInfo?.fontColor || "#fff" }}
                  >
                    {categoryInfo ? categoryInfo.name : ""}
                  </h1>
                </Flex>
                {categoryInfo?.infoMessage && (
                  <Flex
                    className="info-card"
                    style={{
                      background: categoryInfo.infoBackgroundColor,
                      color: categoryInfo.infoFontColor,
                    }}
                  >
                    {categoryInfo?.tagMessage && (
                      <div
                        className="info-tag"
                        style={{
                          background: categoryInfo?.tagBackgroundColor,
                          color: categoryInfo?.tagFontColor,
                        }}
                      >
                        {categoryInfo.tagMessage}
                      </div>
                    )}
                    <div
                      className="info-message"
                      style={{
                        color: categoryInfo.infoFontColor,
                        background: categoryInfo.infoBackgroundColor,
                      }}
                    >
                      {categoryInfo.infoMessage}
                    </div>
                  </Flex>
                )}
                {combinedBadges.length > 0 ? (
                  <Carousel slides={combinedBadges} />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No badges available"
                    style={{ color: "white" }}
                    imageStyle={{ opacity: ".2" }}
                  />
                )}

                <a
                  style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: "inline-block",
                    justifyContent: "center",
                    color: categoryInfo?.buttonTextColor,
                    backgroundColor: categoryInfo?.buttonColor,
                    textDecoration: "none",
                    borderRadius: "5px",
                    margin: "10px 0;",
                    padding: "5px 5px 5px 10px",
                    width: "120px",
                    margin: "10px 0",
                  }}
                  href={"https://" + categoryInfo?.buttonLinkURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {categoryInfo?.buttonLinkURL && (
                    <Flex align="center">
                      <span
                        style={{
                          position: "relative",
                          top: "-1px",
                          marginRight: "5px",
                          fontSize: "13px",
                        }}
                      >
                        {categoryInfo?.buttonName}
                      </span>
                      <DoubleRightOutlined style={{ opacity: ".5" }} />
                    </Flex>
                  )}
                </a>
              </div>
            );
          default:
            return null;
        }
      });

  return (
    <Row gutter={[16, 16]} wrap>
      <Col span={24}>
        <div className="landing-template">{template}</div>
      </Col>
    </Row>
  );
};

export default Main;
